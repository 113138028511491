import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: black;
  margin: 0 auto;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }
  .loader {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.25rem solid rgba(10, 10, 10, 0.2);
    border-right: 0.25rem solid rgba(10, 10, 10, 0.2);
    border-bottom: 0.25rem solid rgba(10, 10, 10, 0.2);
    border-left: 0.25rem solid #18aab2;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => (
  <Wrapper>
    <div>
      <div className="loader"></div>
      <p>lokaly.shop laden</p>
    </div>
  </Wrapper>
);

export default Loader;
