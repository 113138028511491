import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  max-width: 6rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    max-width: 100%;
    max-height: 7rem;
  }
`;
const Wrapper = styled.header`
  position: absolute;
  top: 7rem;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const Header = ({ content }) => {
  return (
    <>
      <Wrapper>
        <StyledContainer>
          <Logo src={content.logo} alt="" />
          <a>{content.contact}</a>
        </StyledContainer>
      </Wrapper>
    </>
  );
};

export default Header;
