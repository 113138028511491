import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useMediaQuery } from 'beautiful-react-hooks';
import GlobalStyle from '../styling/GlobalStyle';
import theme from '../styling/theme';
import CheckoutModal from './CheckoutModal';
import ActionBar from './ActionBar';
import CartModal from './CartModal';
import Footer from './Footer';

const Layout = ({ children, color = 'black', content, is404 }) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (
    <ThemeProvider theme={{ ...theme, primaryColor: color }}>
      <>
        <GlobalStyle />
        {!is404 && <ActionBar content={content} />}
        <main>{children}</main>
        {!is404 && <CheckoutModal content={content} />}
        {isMobile && !is404 && <CartModal />}
        {!is404 && <Footer content={content} />}
      </>
    </ThemeProvider>
  );
};

export default Layout;
