import React, { useContext } from 'react';
import Modal from './Modal';
import { store } from '../store/store';
import Form from './Form';

const CheckoutModal = ({ content }) => {
  const { state } = useContext(store);
  return (
    <Modal trigger={state.showCheckout}>
      {!state.formSentSuccesfully ? (
        <>
          <h2>Bestelling afronden</h2>
          <p>{content.checkoutText}</p>
          <Form content={content} />
        </>
      ) : (
        <>
          <h2>Succesvol verzonden!</h2>
          <p>{content.checkoutSuccess}</p>
        </>
      )}
    </Modal>
  );
};

export default CheckoutModal;
