import { createGlobalStyle } from 'styled-components';
import BerlingskeSerifBoldWoff2 from '../static/fonts/BerlingskeSerif-Bold.woff2';
import BerlingskeSerifBoldWoff from '../static/fonts/BerlingskeSerif-Bold.woff';

// set basic styling and 'normalize' things
const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'BerlingskeSerif Bold';
    src: url(${BerlingskeSerifBoldWoff}) format('woff'),
      url(${BerlingskeSerifBoldWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    overflow: auto;
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.fontFamily};
    background-color: white;
    color: ${({ theme }) => theme.textColor};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamilyHeading};
    font-weight: normal;
		line-height: 1;
    
    + p {
      margin-top: 1.5rem;
    }
  }
  
  h2 {
    font-size: 3.5rem;
    
    @media (${({ theme }) => theme.respondTo.tablet}) {
      font-size: 4rem;
    }
  }
  
  h3 {
    font-size: 2.5rem;
  }
  
  h4 {
    font-size: 2rem;
  }

  p {
    margin: 0;
    padding: 0;
    + p {
      margin-top: 1.5rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    font-family: ${({ theme }) => theme.font};
  }

	img {
		max-width: 100%;
		display: block;
	}

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

`;

export default GlobalStyle;
