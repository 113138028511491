import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { store } from '../store/store';
import Button from './Button';
import { formatPrice } from '../utils/helpers';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.grey};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;

const Image = styled.div`
  position: relative;
  height: 20rem;
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 1s ${({ theme }) => theme.transition};
  }
`;

const ContentWrapper = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  font-size: 2rem;
`;

const Description = styled.p`
  font-size: 1.6rem;
  margin: 1rem 0;
`;

const Price = styled.p`
  display: inline-block;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  border-radius: 3px;
  background: white;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
`;

const StyledButton = styled(Button)`
  margin-top: auto;
`;

const Product = ({ product }) => {
  const [hasItemInCart, setHasItemInCart] = useState(0);
  const [hasClicked, setHasClicked] = useState(false);
  const { dispatch } = useContext(store);

  const handleClick = (type, data) => {
    dispatch({ type, data });
    setHasClicked(true);
    setTimeout(() => {
      setHasClicked(false);
      setHasItemInCart(hasItemInCart + 1);
    }, 1500);
  };

  return (
    <Wrapper>
      <Image>
        <img referrerPolicy="no-referrer" src={product.image} alt="" />
        <Price>{formatPrice(product.price)}</Price>
      </Image>
      <ContentWrapper>
        <Content>
          <Title>{product.title}</Title>
          <Description>{product.description}</Description>
        </Content>
        <StyledButton onClick={() => handleClick('ADD_TO_CART', product)}>
          {!hasClicked && hasItemInCart === 0
            ? 'Toevoegen'
            : hasClicked && 'Toegevoegd!'}
          {!hasClicked && hasItemInCart > 0 && 'Nog 1 toevoegen'}
        </StyledButton>
        {/* <button onClick={() => handleClick("REMOVE_FROM_CART", product)}>
          Delete
        </button> */}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Product;
