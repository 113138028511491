import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Modal from './Modal';
import { store } from '../store/store';
import Cart from './Cart';
import shopping from '../static/images/shopping.svg';
import { useEffect } from 'react';

const Button = styled.button`
  @keyframes wobble {
    0%,
    100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
    }
    15% {
      -webkit-transform: translateX(-30px) rotate(-6deg);
      transform: translateX(-30px) rotate(-6deg);
    }
    30% {
      -webkit-transform: translateX(15px) rotate(6deg);
      transform: translateX(15px) rotate(6deg);
    }
    45% {
      -webkit-transform: translateX(-15px) rotate(-3.6deg);
      transform: translateX(-15px) rotate(-3.6deg);
    }
    60% {
      -webkit-transform: translateX(9px) rotate(2.4deg);
      transform: translateX(9px) rotate(2.4deg);
    }
    75% {
      -webkit-transform: translateX(-6px) rotate(-1.2deg);
      transform: translateX(-6px) rotate(-1.2deg);
    }
  }

  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  appearance: none;
  background: white;
  border: none;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  box-shadow: 0 20px 20px 0 #0000001f;
  z-index: 1;
  animation: ${({ wobble }) => wobble && 'wobble 0.8s both'};

  svg {
    width: 2.5rem;
    height: auto;

    #custom-color {
      fill: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const Count = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -0.5rem;
  right: -0.5rem;
  width: 2rem;
  height: 2rem;
  background: black;
  color: white;
  z-index: 1;
  border-radius: 50%;
  font-size: 1.25rem;
`;

const CartModal = () => {
  const [shouldWobble, setShouldWobble] = useState(false);
  const { state, dispatch } = useContext(store);

  const handleClick = () => {
    dispatch({ type: 'TOGGLE_MOBILE_CART' });
    dispatch({ type: 'TOGGLE_MODAL_IS_OPEN' });
  };

  useEffect(() => {
    if (state.cart.products.length === 0) return;
    setShouldWobble(true);
    setTimeout(() => {
      setShouldWobble(false);
    }, 800);
  }, [state.cart.products.length]);

  return (
    <>
      <Button wobble={shouldWobble} onClick={() => handleClick()}>
        <Count>{state.cart.products.length}</Count>
        <SVG src={shopping} />
      </Button>
      <Modal trigger={state.showMobileCart}>
        <Cart />
      </Modal>
    </>
  );
};

export default CartModal;
