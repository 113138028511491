import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.primaryColor};
  color: white;
  padding: 1.25rem 0;
  z-index: 2;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    padding: 1.5rem 0;
    font-size: 1.5rem;
  }
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    text-decoration: underline;
  }
  @media (${({ theme }) => theme.respondTo.mobile}) {
    p {
      margin: 0;
    }
  }
`;

const Footer = ({ content }) => {
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <StyledContainer>
        <p>
          {content.company} — {year}
        </p>
        <span>
          Gratis gemaakt met{' '}
          <a href="https://lokaly.shop" target="_blank" rel="noopener nofollow">
            lokaly.shop
          </a>{' '}
          🤘
        </span>
      </StyledContainer>
    </Wrapper>
  );
};

export default Footer;
