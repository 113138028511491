import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
// import GlobalStyle from '../styling/GlobalStyle';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 0 1.6rem;
  text-align: center;

  h1 {
    font-size: 4.5rem;
    width: 100%;
    margin: 0 0 2rem;
  }

  h2 {
    font-size: 2.75rem;
    color: grey;
  }
`;

const Page404 = () => {
  return (
    <Layout is404>
      <Wrapper>
        <div>
          <h1>Oeps!</h1>
          <h2>Deze lokaly.shop lijkt niet te bestaan..</h2>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Page404;
