import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import SectionWrapper from './SectionWrapper';
import SVG from 'react-inlinesvg';
import Shopping from '../static/images/shopping.svg';
import Delivery from '../static/images/delivery.svg';
import Suprise from '../static/images/suprise.svg';

const ContentWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4.8rem 0 0;
`;

const Step = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    max-width: 27rem;
    margin: 0 3rem;
  }

  svg {
    width: auto;
    height: 7rem;

    #custom-color {
      fill: ${({ theme }) => theme.primaryColor};
    }

    @media (${({ theme }) => theme.respondTo.tablet}) {
      width: 9rem;
      height: 9.5rem;
    }
  }

  p {
    width: 100%;
    margin: 2.4rem 0 0;

    @media (${({ theme }) => theme.respondTo.mobile}) {
      font-size: 1.4rem;
    }
  }
`;

const Intro = ({ content }) => {
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <h2>{content.stepsTitle}</h2>
          <StepsWrapper>
            <Step>
              <SVG src={Shopping} />
              <p>{content.step1}</p>
            </Step>
            <Step>
              <SVG src={Delivery} />
              <p>{content.step2}</p>
            </Step>
            <Step>
              <SVG src={Suprise} />
              <p>{content.step3}</p>
            </Step>
          </StepsWrapper>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Intro;
