import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ active }) =>
    active ? 'rgba(0, 0, 0, 0.45)' : 'transparent'};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  will-change: background;
  transition: all 0.25s;
  z-index: 1;
`;

const Background = ({ state, handleClick }) => {
  return <Wrapper onClick={() => handleClick()} active={state.modalIsOpen} />;
};

export default Background;
