import React, { useContext } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import SVG from 'react-inlinesvg';
import { store } from '../store/store';
import Background from './Background';
import Close from '../static/images/close.svg';

const Inner = styled.div`
  position: relative;
  padding: 2.4rem 1.6rem;
  background: white;
  width: 100%;
  pointer-events: all;
  border-radius: 4px 4px 0 0;
  overflow: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    max-width: 50rem;
    padding: 4rem;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 20px 20px 0 #0000001f;
  }
`;

const Wrapper = styled(animated.div)`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  pointer-events: none;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    max-height: 75vh;
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    bottom: auto;
    top: 50%;
  }
`;

const StyledSVG = styled(SVG)`
  position: absolute;
  right: 1.6rem;
  top: 2.4rem;
  width: 2.5rem;
  height: 2.5rem;
  pointer-events: all;
  cursor: pointer;
`;

const Modal = ({ children, trigger }) => {
  const { state, dispatch } = useContext(store);
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const isMobile = useMediaQuery('(max-width: 768px)');

  const transitions = useTransition(trigger, null, {
    from: {
      opacity: isDesktop ? 0 : 1,
      transform: `translateY(${isDesktop ? '-20%' : '100%'})`,
    },
    enter: {
      opacity: 1,
      transform: `translateY(${isDesktop ? '-50%' : '0'})`,
    },
    leave: {
      opacity: isDesktop ? 0 : 1,
      transform: `translateY(${isDesktop ? '-20%' : '100%'})`,
    },
  });

  const handleClick = () => {
    dispatch({ type: 'TOGGLE_MODAL_IS_OPEN' });
    isMobile &&
      state.showMobileCart &&
      dispatch({ type: 'TOGGLE_MOBILE_CART' });

    state.showCheckout && dispatch({ type: 'TOGGLE_CHECKOUT' });
  };

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Wrapper key={key} style={props}>
              <Inner>
                {children}
                <StyledSVG src={Close} onClick={() => handleClick()} />
              </Inner>
            </Wrapper>
          )
      )}
      <Background state={state} handleClick={handleClick} />
    </>
  );
};

export default Modal;
