import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Intro from '../components/Intro';
import Shop from '../components/Shop';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Page404 from './404';
import Loader from '../components/Loader';

const IntroWrapper = styled.div`
  overflow: hidden;
`;

const Home = () => {
  const [content, setContent] = useState(null);
  const [is404, setIs404] = useState(false);

  const subdomain = window.location.host.split('.')[0];

  const processData = (copyData, imagesData) => {
    const copy = copyData.valueRanges[0].values;
    const products = copyData.valueRanges[1].values;
    const images = imagesData.items;
    delete products[0]; // delete first row as it's only title, description etc.

    let formattedCopy = {};
    copy.map(row => {
      const key = row[0];
      let value = row[1];
      if (value.includes('?id=') || value.includes('/view?')) {
        // get image link by ID
        if (value.includes('?id')) {
          const imageId = value.split('?id=')[1];
          const matchedImage = images.filter(image => image.id === imageId);
          value = matchedImage[0]?.thumbnailLink.replace(
            '=s220',
            `=s${600 * window.devicePixelRatio}`
          );
        } else if (value.includes('/view?')) {
          const imageId = value
            .split('/view?')[0]
            .replace('https://drive.google.com/file/d/', '');
          const matchedImage = images.filter(image => image.id === imageId);
          value = matchedImage[0]?.thumbnailLink.replace(
            '=s220',
            `=s${600 * window.devicePixelRatio}`
          );
        }
      }

      // Create final object
      formattedCopy = { ...formattedCopy, [key]: value };
    });

    let formattedProducts = [];
    products.map(row => {
      const title = row[0];
      const description = row[1];
      const price = row[2];

      // Now, get the image link by ID
      if (row[3].includes('?id=')) {
        const imageId = row[3].split('?id=')[1];
        const matchedImage = images.filter(image => image.id === imageId);
        const imageURL = matchedImage[0]?.thumbnailLink.replace(
          '=s220',
          `=s${300 * window.devicePixelRatio}`
        );

        // Create final object
        formattedProducts = [
          ...formattedProducts,
          { title, description, price, image: imageURL },
        ];
      } else if (row[3].includes('/view?')) {
        const imageId = row[3]
          .split('/view?')[0]
          .replace('https://drive.google.com/file/d/', '');
        console.log(imageId);
        const matchedImage = images.filter(image => image.id === imageId);
        const imageURL = matchedImage[0]?.thumbnailLink.replace(
          '=s220',
          `=s${300 * window.devicePixelRatio}`
        );

        // Create final object
        formattedProducts = [
          ...formattedProducts,
          { title, description, price, image: imageURL },
        ];
      }
    });

    const content = { ...formattedCopy, products: [...formattedProducts] };

    setContent(content);
  };
  const getContent = data => {
    (async () => {
      try {
        const { sheetsId, imagesFolderId } = data[subdomain];
        const key = 'AIzaSyAVgceyaRKRyv3ju_JuvABTQs2NYLS79No';
        const content = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${sheetsId}/values/:batchGet?ranges=Text&ranges=Products&majorDimension=ROWS&key=${key}`
        );
        const images = await axios.get(
          `https://www.googleapis.com/drive/v2/files?q=%27${imagesFolderId}%27%20in%20parents&key=${key}`
        );
        processData(content.data, images.data);
      } catch (error) {
        console.log(error);
        setIs404(true);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          `${process.env.PUBLIC_URL}/clients.json`
        );
        getContent(data);
      } catch (error) {
        console.log(error);
        setIs404(true);
      }
    })();
  }, []);

  if (!content && !is404) {
    return <Loader />;
  }

  if (is404) {
    return <Page404 />;
  }

  return (
    <Layout color={content.color} content={content}>
      <Helmet
        title={`${content.company} — lokaly.shop`}
        meta={[
          {
            name: `description`,
            content: content.subtitle,
          },
          {
            property: `og:title`,
            content: `${content.company} — lokaly.shop`,
          },
        ]}
      />
      <IntroWrapper>
        <Header content={content} />
        <Hero content={content} />
        <Intro content={content} />
      </IntroWrapper>
      <Shop content={content} products={content.products} />
    </Layout>
  );
};

export default Home;
