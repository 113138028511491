import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'beautiful-react-hooks';
import Container from './Container';
import Cart from './Cart';
import Products from './Products';
import SectionWrapper from './SectionWrapper';

const StyledSectionWrapper = styled(SectionWrapper)`
  border-top: 1px solid ${({ theme }) => theme.grey};
  padding-top: 3.5rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding-top: 10rem;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 3.5rem;
`;

const Intro = styled.div`
  margin: 0 auto 4rem;
  text-align: center;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 0 auto 6.4rem;
    max-width: 75%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Shop = ({ content, products }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  return (
    <>
      <StyledSectionWrapper>
        <Container>
          <Intro>
            <Title>{content.productsTitle}</Title>
            <p>{content.productsText}</p>
          </Intro>
        </Container>
        <Container>
          <ContentWrapper>
            {isDesktop && <Cart />}
            <Products content={content} products={products} />
          </ContentWrapper>
        </Container>
      </StyledSectionWrapper>
    </>
  );
};

export default Shop;
