import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import Product from './Product';

const Wrapper = styled.div`
  flex: 1;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 2rem;
`;

const Footnote = styled.p`
  color: grey;
  text-align: center;
  max-width: 60rem;
  margin: 7.5rem auto 0;
  font-size: 1.5rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 10rem auto 0;
    font-size: inherit;
  }
`;

const Products = ({ content, products }) => {
  return (
    <Wrapper>
      <Grid>
        {products.map(product => (
          <Product product={product} key={shortid.generate()} />
        ))}
      </Grid>
      <Footnote>{content.productsFootnote}</Footnote>
    </Wrapper>
  );
};

export default Products;
